(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('mhUpdateMortgageModal', mhUpdateMortgageModal);
    function mhUpdateMortgageModal () {
        return {
            restrict: 'E',
            scope: { mortgage: '<', policyId: '<', isEscrowCheckout: '<' },
            templateUrl: 'js/modals/update-mortgage-modal/template.html',
            controller: function ($scope, $mdDialog, toaster, APIService, spinnerService) {
                $scope.forms = {};
                $scope.escrowFormData = _.cloneDeep($scope.mortgage);
                $scope.cancel = function () { $mdDialog.cancel(); };

                $scope.editLender = function () {
                    const escrowForm = $scope.forms.escrow;
                    if (escrowForm.$valid) {
                        const updateFormat = { [$scope.escrowFormData.id]: $scope.escrowFormData };
                        const mortgageUpdate = {property_data: {mortgages: { details: updateFormat }}};

                        spinnerService.show('globalSpinner');
                        APIService.updatePolicyMortgages($scope.policyId, mortgageUpdate).then((updatedPolicy) => {
                            // Hack to update 'Mortgage Lender Information Section'
                            if ($scope.escrowFormData.type === 'first') {
                                const escrowSection = document.querySelector('[key="escrow_section"]');
                                Object.keys($scope.escrowFormData).forEach(fieldKey => {
                                    const field = escrowSection.querySelector(`input[name*="${fieldKey}"]`);
                                    if (field) {
                                        field.value = $scope.escrowFormData[fieldKey];
                                    }
                                });
                            }
                            spinnerService.hide('globalSpinner');
                            toaster.pop('success', 'Mortgages', 'Saved');
                            $mdDialog.hide(updatedPolicy);
                        }).catch(err => {
                            spinnerService.hide('globalSpinner');
                            toaster.pop('error', 'Mortgages', err || 'Unable to save');
                        });
                    }
                };
            }
        };
    }
})(window.angular, window._);

